<template>
<!-- 智慧化健身房 左下 -->
    <div class="ticai darkBack">
        <div class="ticaitop">
            <div style="color:#fff;font-size:0.2rem">智慧化健身房</div>
            <div class="ticainput">
                <input type="text" placeholder="请输入" v-model="gymName" @keyup.enter="search">
                <div @click="search">搜索</div>
                <img src="../../../assets/images/sousuo.png" alt="">
            </div>
        </div>
        <div class="province">
            <span>{{province}}</span>
            <img src="../../../assets/images/sanjiao.png" alt="" @click="counties = !counties" v-if="iconShow">
        </div>
        <ul class="counties" v-show="counties">
            <li class="countiesli" v-for="(item,index) in select" :key="index" @click="countiesclick(item)">{{item.title}}</li>
        </ul>
        <div style="text-align:center">
            <table border="0" cellspacing="0" cellpadding="0" >
                <tr style="color:#20D7FE;line-height:0.24rem" align="left">
                    <th>场馆名称</th>
                    <th>累计运动人数</th>
                </tr>
                <tr @click="swimming(item)" class="venues" style="line-height:0.24rem;" v-for="(item, index) in cainum" :key="index" align="left">
                    <td class="peo_text">{{item.title}}</td>
                    <td class="peo_line">{{item.online_count}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";
import bus from "/src/assets/js/bus";
import lotteryjson from '/public/data/lottery.json'

export default {
    data () {
        return {   
            iconShow:true,
            counties:false,
            province:'绍兴市',     
            cainum:[       
                // {name: '迪荡街道云东社区智慧化健身房', money: '831'},
                // {name: '府山街道越都社区智慧化健身房', money: '821'},
                // {name: '城南街道高立社区智慧化健身房', money: '795'},
                // {name: '鉴湖街道玉屏村智慧化健身房', money: '753'},
                // {name: '鉴湖街道丰乐村智慧化健身房', money: '721'},
                // {name: '绍兴市柯桥区福全街道健身房', money: '689'},
                // {name: '灵芝街道大树港居智慧化健身房', money: '642'},
                // {name: '东浦街道王城寺村智慧化健身房', money: '622'},
                // {name: '马山街道安城社区智慧化健身房', money: '612'},
                // {name: '孙端街道红鲍村智慧化健身房', money: '598'},
                // {name: '绍兴市柯桥区漓渚镇九板桥', money: '571'},
                // {name: '陶堰街道邵家娄村智慧化健身房', money: '534'},
                // {name: '孙端街道樊浦村智慧化健身房', money: '472'},
                // {name: '陶堰街道白塔头村智慧化健身房', money: '467'},
                // {name: '陶堰街道张家岙村智慧化健身房', money: '452'},
                // {name: '道墟街道钱上村智慧化健身房', money: '428'},
            ],
            select:[{
                    title:'绍兴市',
                    id:330600
                },{
                    title:'越城区',
                    id:330602
                },{
                    title:'新昌县',
                    id:330624
                },{
                    title:'诸暨市',
                    id:330681
                },{
                    title:'柯桥区',
                    id:330621
                },{
                    title:'上虞区',
                    id:330682
                },{
                    title:'嵊州市',
                    id:330683
                },
            ],
            dataTimer:"",
            gymName:"",
            location:true,  //是否需要定位
        }
    },
    methods: {
        countiesclick(id){
            let _this = this;
            // console.log(id)
            var stree = event.target.innerText;
            this.province = stree;
            this.counties = false;
            bus.$emit("jianshenfangArea",stree)
            // console.log(stree)
            if(stree == "绍兴市"){
                this.getData("市",stree);
                clearInterval(this.dataTimer)
                this.dataTimer = setInterval(() => {
                    this.getData("市",stree);
                }, 3000);
            }else{
                this.getData("区",stree);
                clearInterval(this.dataTimer)
                this.dataTimer = setInterval(() => {
                    this.getData("区",stree);
                }, 3000);
            } 
        },
        swimming(item){
            this.$router.push({
                path: `/swimming?id=${item.id}&title=${item.title}`
            })
        },
        // 获取数据
        getData(type,name){
            let data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }

            let url;
            if(type == '市') url = window.appHost+"/api/v1/screen/Venue/onlinelist";
            else url = window.appHost+"/api/v1/screen/Venue/onlinelist?area="+name

            let result = window.ajaxRequest(url,JSON.stringify(data),"post")
            // console.log(result)
            if(result.code == 1){
                this.location = true;
                this.cainum = result.data
                // result.data.forEach((item,index)=>{
                //     this.cainum.push({
                //         name:item.title,
                //         money:item.online_count
                //     })
                // })
                if(lotteryjson.people.data.center[0] == parseFloat(result.data[0].lng) && 
                    lotteryjson.people.data.center[1] == parseFloat(result.data[0].lat))
                {
                    // console.log("位置相同，无需定位")
                }else{
                    lotteryjson.people.data.center = [parseFloat(result.data[0].lng),parseFloat(result.data[0].lat)]
                    lotteryjson.people.data.points = [];

                    result.data.forEach((item,index)=>{
                        if(item.lng > 0){
                            lotteryjson.people.data.points.push({
                                location:[parseFloat(item.lng),parseFloat(item.lat)],
                                name:item.title,
                                id:item.id
                            })
                        }
                    })
                    // console.log(lotteryjson.people)
                    this.plug.upDataMap(lotteryjson.people)
                }
                
            }
            
        },
        // 搜索
        search(){
            let info = JSON.parse(window.localStorage.getItem('screenUserInfo'))
            let type;
            let url;
            if(info.is_city == 1){
                url = window.appHost+"/api/v1/screen/Venue/onlinelist?keyword="+this.gymName
            }else{
                url = window.appHost+"/api/v1/screen/Venue/onlinelist?area="+info.name+"&keyword="+this.gymName
            }
            let data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            let result = window.ajaxRequest(url,JSON.stringify(data),"post")
            // console.log(result)
            if(result.code == 1){
                this.cainum = result.data;

                lotteryjson.people.data.center = [parseFloat(result.data[0].lng),parseFloat(result.data[0].lat)]
                lotteryjson.people.data.points = [];

                result.data.forEach((item,index)=>{
                    if(item.lng > 0){
                        lotteryjson.people.data.points.push({
                            location:[parseFloat(item.lng),parseFloat(item.lat)],
                            name:item.title,
                            id:item.id
                        })
                    }
                })
                // console.log(lotteryjson.people)
                this.plug.upDataMap(lotteryjson.people)
            }
        }
    },
    watch:{
        gymName(val,vall){
            if(val.length>0){
                clearInterval(this.dataTimer)
            }else{
                let info = JSON.parse(window.localStorage.getItem('screenUserInfo'))
                // console.log(info)
                if(info.is_city == 1){
                    this.getData('市','绍兴市');
                    this.dataTimer = setInterval(() => {
                        this.getData('市','绍兴市');
                    }, 3000);
                    this.province = "绍兴市";
                } else{
                    this.getData('区',info.name);
                    this.dataTimer = setInterval(() => {
                        this.getData('区',info.name);

                    }, 3000);
                    this.province = info.name;
                } 
            }
        }
    },
    mounted () {
        let info = JSON.parse(window.localStorage.getItem('screenUserInfo'))
        // console.log(info)
        if(info.is_city == 1){
            this.getData('市','绍兴市');
            this.dataTimer = setInterval(() => {
                this.getData('市','绍兴市');
            }, 3000);
            this.province = "绍兴市";
            this.iconShow = true;
        } else{
            this.getData('区',info.name);
            this.dataTimer = setInterval(() => {
                this.getData('区',info.name);

            }, 3000);
            this.province = info.name;
            this.iconShow = false;
        } 
    },
    beforeDestroy () {
        clearInterval(this.dataTimer)
    }
}
</script>
 
<style scoped>
.peo_text{
    width:2.6rem
}
.ticai{
    width: 5.06rem;
    height:5.50rem;
    padding: 0.2rem;
    color:#ffffff;
    font-size: 0.20rem;
    position: relative;
}
.ticaitop{
    display: flex;
    justify-content: space-between;
}
.ticaitop>div:nth-child(1){
    float: left;
}
/* .ticaitop>div{
    width:50%;
} */
.ticaitop>div:nth-child(2){
    float: right;
}
.ticainput{
    position: relative;
    width:2.05rem;
    height:0.25rem;
}
.ticainput>input{
    width:2.05rem;
    height:0.25rem;
    text-indent: 0.20rem;
    background:none;  
	outline:none;  
	border:0.01rem solid #5d5d5d;
    border-radius: 0.125rem;
    color:#FFFFFF;
    box-sizing: border-box;
}
.ticainput>div{
    width:0.57rem;
    height:0.24rem;
    line-height: 0.24rem;
    border-radius: 0.125rem;
    background:#5589FF;
    position: absolute;
    top:0.01rem;
    right:0;
    font-size: 0.13rem;
    text-align: center;
    color:#08142C;
    font-weight: 500;
    cursor: pointer;
}
.ticainput>img{
    width:0.14rem;
    height:0.14rem;
    position: absolute;
    top:0.06rem;
    left:0.06rem;
}
.ticai table{
    width:4.2rem;
    height:4.10rem;
    display:inline-block;
    margin-top:0.2rem;
    overflow: hidden;
    font-size: 0.14rem;
    color:#7EA7E2;
}
.province{
    font-size: 0.16rem;
    color:#20D7FE;
    margin-top:0.2rem;
}
.province>img{
    width:0.1rem;
    height:0.08rem;
    margin-left:0.12rem;
    cursor: pointer;
}
@media screen and (max-width: 1000px) {
    .peo_text{
        width:2.6rem;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .peo_line{
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
    }
}
.venues{
    /* height: 5.2rem; */
    overflow-y: auto;
}
 /*滚动条样式*/
.venues::-webkit-scrollbar {
    width: 0.05rem;    
    height: 0rem;
}


.venues::-webkit-scrollbar-thumb {
    border-radius: 0.1rem;
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    background: #7EA7E2;
}
.venues::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    border-radius: 0.1rem;
}
.counties{
    display: inline-block;
    position: absolute;
    top:1rem;
    left:0;
    color:#F1F1F1;
    font-size: 0.19rem;
    text-align: center;
    background:#000000CC;
    padding:0.06rem 0rem;
    box-sizing: border-box;
    max-height:4.1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;
}
.counties::-webkit-scrollbar {
        display: none;
    }
.countiesli{
    width:1.09rem;
    line-height: 0.40rem;
}
.countiesli:hover{
    color:#20D7FE;
    cursor: pointer;
}
</style>